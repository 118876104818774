import { useCheckSubscriptionQuery } from '@/app/api';
import { Colors } from '@/app/constants/colors';
import { useAppSelector } from '@/app/hooks';
import { UILoader } from '@/shared/ui/ui-loader';
import { Box, Stack, SxProps, Typography, TypographyProps } from '@mui/material';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalRef } from '@/app/helpers';
import { EmployeesNumberModal } from '@/pages/employees-page/ui/employees-number-modal';

interface Props {
  sx?: SxProps;
  TitleProps?: TypographyProps;
}

export const TariffHeaderMenu: FC<Props> = ({ sx, TitleProps }) => {
  const { t } = useTranslation();
  const { cabinetType } = useAppSelector((state) => state.app);
  const { data, isLoading } = useCheckSubscriptionQuery({}, { skip: cabinetType !== 'customer' });
  const employeesNumberModalRef = useRef<BaseModalRef>(null);

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      sx={{
        backgroundColor: 'rgba(0, 0, 0, .2)',
        ...sx,
      }}
    >
      {isLoading ? (
        <UILoader size={20} sx={{ mx: 3 }} />
      ) : (
        data && (
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            width='max-content'
            sx={{
              px: 2.5,
            }}
          >
            <Box>
              <Typography color={Colors.PRIMARY} {...TitleProps}>
                {t('tariff')}:
              </Typography>

              <Typography color={Colors.WHITE_TEXT} fontWeight={600}>
                {data?.data?.tariffName ?? t('not_purchased')}
              </Typography>
            </Box>

            {data?.success !== false && (
              <Box
                onClick={() => {
                  employeesNumberModalRef.current?.open();
                }}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Typography color={Colors.PRIMARY} {...TitleProps}>
                  {t('amount')}:
                </Typography>

                <Typography color={Colors.WHITE_TEXT}>
                  {data?.data.activatedUsers ?? 0} /{" "}
                  <Box
                    component="span"
                    sx={{
                      opacity: 0.4,
                    }}
                  >
                    {data.data.activatedUsersLimit}
                  </Box>
                </Typography>
              </Box>
            )}
          </Stack>
        )
      )}
      <EmployeesNumberModal ref={employeesNumberModalRef} />
    </Stack>
  );
};
