import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import ModalTemplate from '@/shared/modal-template';
import { Dialog, FormControl, FormHelperText, Stack } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { FormNumericInput } from '@/shared/inputs/form-numeric-input';
import FormFormattedInput from '@/shared/inputs/form-formatted-input';
import { UILoader } from '@/shared/ui/ui-loader';
import { FormTextInput } from '../../../shared/inputs/form-text-input';
import { useOpen } from '@/app/hooks';
import {
  useAddRowPaymentRegisterMutation,
  useEditRowPaymentRegisterMutation,
  useLazyGetCardInfoQuery,
  useLazyGetInfoByTinOrPinflQuery,
} from '@/app/api';
import notify from '@/shared/toaster/lib/notify.tsx';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors.ts';
import {
  PaymentRegistryModalFormValues,
  PaymentRegistryModalProps,
  PaymentRegistryModalRef,
} from '../types';

export const PaymentRegisterModal = forwardRef<PaymentRegistryModalRef, PaymentRegistryModalProps>(
  ({ updateRow, refetch }, ref) => {
    const { t } = useTranslation();
    const { open, setOpen } = useOpen();
    const [unique, setUnique] = useState<PaymentRegistryModalFormValues['unique'] | undefined>(
      undefined
    );
    const [mode, setMode] = useState<'add' | 'edit'>('add');

    const { control, handleSubmit, setValue, reset, watch } =
      useForm<PaymentRegistryModalFormValues>({
        mode: 'onChange',
      });

    const [getInfoByTinOrPinfl, { isFetching: isPinflFetching }] =
      useLazyGetInfoByTinOrPinflQuery();

    const [editRegisterRow, { isLoading: isEditRegisterRowLoading }] =
      useEditRowPaymentRegisterMutation();
    const [addRegisterRow, { isLoading: isAddRegisterRowLoading }] =
      useAddRowPaymentRegisterMutation();
    const [
      getCardInfo,
      {
        data: cardInfo,
        isError: isCardInfoError,
        error: cardInfoError,
        isFetching: isCardInfoLoading,
      },
    ] = useLazyGetCardInfoQuery();

    const cardNumber = watch('cardNumber') ?? '';

    useImperativeHandle(ref, () => ({
      init: init,
      close: onClose,
    }));

    const init: PaymentRegistryModalRef['init'] = (data) => {
      if (data && data.mode === 'edit') {
        // ! unique и mode не нужны в форме, поэтому их убираем деструктуризацией
        const { unique, mode, ...rest } = data;
        setMode(mode);
        reset({
          ...rest,
          amount: data.amount ? (+data?.amount / 100).toFixed(2) : 0,
        });
      } else {
        reset({
          cardNumber: '',
          cardOwner: '',
          unique: unique,
        });
      }
      setUnique(data?.unique);
      onOpen();
    };

    const onOpen = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
      setUnique(undefined);
      setMode('add');
      refetch();
      reset();
    };

    const onSubmitHandler: SubmitHandler<PaymentRegistryModalFormValues> = async (data) => {
      const addOrEditBody = {
        ...data,
        amount: +data.amount * 100,
        cardNumber: data.cardNumber.replace(/\D/g, ''),
        ...(typeof unique === 'number' && { unique }),
      };

      if (mode === 'add') {
        await addRegisterRow(addOrEditBody).unwrap();
      } else {
        const response = await editRegisterRow(addOrEditBody).unwrap();
        updateRow(response);
      }
      notify(t('toast.success'), 'success');
      onClose();
    };

    const cardInfoOwnerName = useMemo(() => {
      if (isCardInfoError) {
        if ('data' in cardInfoError && cardInfoError.data) {
          return cardInfoError.data.error.msg;
        }
        return t('card_number_error');
      }
      if (cardInfo?.data.name) {
        return cardInfo.data.name;
      }
      return '';
    }, [cardInfo?.data, cardInfoError]);

    const onChangePersonalData = async (value: string | undefined) => {
      if (!value) {
        return setValue('pinfl', '');
      }

      if (value && value.length === 9) {
        const response = await getInfoByTinOrPinfl(value, true).unwrap();

        if (+value[0] > 3 && response.personalNum) {
          setValue('pinfl', response.personalNum);
        }
      }
    };

    const debounceOnChange = _.debounce(onChangePersonalData, 1500);

    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            maxWidth: '560px',
            width: '100%',
          },
        }}
      >
        <ModalTemplate
          title={mode === 'add' ? t('add_register') : t('edit_register')}
          submitBtnText={mode === 'add' ? t('add_register') : t('save')}
          onClose={onClose}
          loading={isAddRegisterRowLoading || isEditRegisterRowLoading}
          onSubmit={handleSubmit(onSubmitHandler)}
          disabled={isCardInfoLoading || isCardInfoError || isPinflFetching}
        >
          <Stack spacing={2}>
            <FormControl>
              <UIFormLabel required>{t('pinfl')}:</UIFormLabel>
              <FormTextInput
                control={control}
                name='pinfl'
                onChange={async (event) => await debounceOnChange(event.target.value)}
                InputProps={{
                  placeholder: t('not_entered'),
                }}
                rules={{
                  required: true,
                  minLength: 9,
                  maxLength: 14,
                }}
              />
            </FormControl>

            <FormControl>
              <UIFormLabel required>{t('card_number')}:</UIFormLabel>
              <FormFormattedInput
                format='#### #### #### ####'
                control={control}
                name='cardNumber'
                rules={{
                  required: true,
                  maxLength: 19,
                }}
                onChange={async (event) => {
                  const value = event.target.value.replace(/\D/g, '');
                  const currentCardNumber = cardNumber.replace(/\D/g, '');
                  if (value.length === 16 && currentCardNumber !== value) {
                    const cardOwnerName = await getCardInfo({ cardNumber: value }, true)
                      .unwrap()
                      .then((res) => res.data.name);
                    setValue('cardOwner', cardOwnerName);
                  }
                }}
                InputProps={{
                  endAdornment: isCardInfoLoading && <UILoader size={20} />,
                  placeholder: '8600 **** **** ****',
                }}
              />
              {cardNumber.replace(/\D/g, '').length === 16 &&
                !isCardInfoLoading &&
                cardInfoOwnerName && (
                  <FormHelperText
                    sx={{
                      color: isCardInfoError ? Colors.ERROR : Colors.BGRAY,
                    }}
                  >
                    {cardInfoOwnerName}
                  </FormHelperText>
                )}
            </FormControl>

            <FormControl>
              <UIFormLabel required>{t('sum')}:</UIFormLabel>
              <FormNumericInput
                control={control}
                name='amount'
                rules={{ required: true }}
                placeholder={t('not_entered')}
              />
            </FormControl>
          </Stack>
        </ModalTemplate>
      </Dialog>
    );
  }
);
