import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { Dialog, Pagination, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ModalTemplate from '@/shared/modal-template';
import { useAppSelector, useFilters, useOpen } from '@/app/hooks';
import { useTranslation } from 'react-i18next';
import { BaseModalRef, checkIfHasPermission, PaginationParams } from '@/app/helpers';
import {
  useGetTaxiCompanyDriversWithCompletedOrdersQuery,
  useLazyExportTaxiCompanyDriversWithCompletedOrdersQuery,
} from '@/app/api';
import { Colors } from '@/app/constants/colors.ts';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { UIButton } from '@/shared/ui/ui-button';
import { PageSizeSelect } from '@/shared/page-size-select';
import PermissionCheckHOC from '@/shared/permission-hoc';
import { FormDatePicker } from '@/shared/inputs/form-date-picker';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { UITable } from '@/shared/ui/ui-table';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import notify from '@/shared/toaster/lib/notify.tsx';
import { CompanyDriverWithCompleteOrdersRequest } from '@/app/api/model';

interface FormValues {
  dateFrom: Date;
  dateTo: Date;
  search: string;
}

const initialFilterParams = {
  page: 1,
  pageSize: 10,
  search: '',
  dateFrom: '',
  dateTo: '',
};

export const EmployeesNumberModal = forwardRef<BaseModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { roles } = useAppSelector((state) => state.auth);
  const { open, setOpen } = useOpen(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      search: '',
    },
  });

  const { filterParams, onChangeFilterParams } = useFilters<
    PaginationParams<CompanyDriverWithCompleteOrdersRequest>
  >({ initialFilterParams });

  const { data, isFetching } = useGetTaxiCompanyDriversWithCompletedOrdersQuery(filterParams, {
    skip: !open,
  });
  const [downloadExcel, { isLoading: isDownloadEmployeeNumberLoading }] =
    useLazyExportTaxiCompanyDriversWithCompletedOrdersQuery();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
    }),
    []
  );

  const onOpen = () => {
    setOpen(true);
    reset();
  };

  const onClose = () => {
    setOpen(false);
    reset();
    onChangeFilterParams(initialFilterParams);
  };

  const onSearchParamsSubmit: SubmitHandler<FormValues> = (data) => {
    onChangeFilterParams({
      ...initialFilterParams,
      search: data.search,
      dateFrom:
        data.dateFrom && dayjs(data.dateFrom).isValid()
          ? dayjs(data.dateFrom).format('DD.MM.YYYY')
          : '',
      dateTo:
        data.dateTo && dayjs(data.dateTo).isValid() ? dayjs(data.dateTo).format('DD.MM.YYYY') : '',
    });
  };

  const handleDownloadExcel = async () => {
    await downloadExcel({
      dateFrom: filterParams.dateFrom || '',
      dateTo: filterParams.dateTo || '',
    }).unwrap();
    notify(t('toast.success'), 'success');
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18722', '18732']), [roles]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '1400px',
          width: '100%',
          background: '#f8f8f8',
        },
      }}
    >
      <ModalTemplate noBtns onClose={onClose}>
        <Stack direction='column' sx={{ backgroundColor: '#ffffff', p: 2, borderRadius: '12px' }}>
          <Stack direction='row' spacing={1} mb={4}>
            {!filterParams.dateFrom && !filterParams.dateTo ? (
              <Typography variant='h6' color={Colors.SECONDARY}>
                {t('employees_number_trip_report')}
              </Typography>
            ) : (
              <Stack direction='row' spacing={1}>
                <Typography variant='h6' color={Colors.SECONDARY}>
                  {t('employees_number_trip_report_range')}
                </Typography>
                <Typography variant='h6' color={Colors.BGRAY}>
                  {filterParams.dateFrom || filterParams.dateTo
                    ? `${filterParams.dateFrom || ''}${filterParams.dateFrom && filterParams.dateTo ? ' - ' : ''}${filterParams.dateTo || ''}`
                    : ''}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack component='form' onSubmit={handleSubmit(onSearchParamsSubmit)}>
            <Stack
              direction={isTablet ? 'row' : 'column'}
              spacing={2}
              justifyContent='space-between'
            >
              <Stack direction='row' flexWrap='wrap' gap={1.5}>
                <FormTextInput control={control} name='search' label={t('search')} />
                <UIButton type='submit'>{t('to_find')}</UIButton>
                <Stack direction='row' spacing={1.5}>
                  <FormDatePicker
                    control={control}
                    name='dateFrom'
                    label={t('from')}
                    format='DD.MM.YYYY'
                  />
                  <FormDatePicker
                    control={control}
                    name='dateTo'
                    label={t('to')}
                    format='DD.MM.YYYY'
                  />
                </Stack>
                <PageSizeSelect
                  value={filterParams.pageSize}
                  onChange={({ target }) =>
                    onChangeFilterParams({ pageSize: Number(target.value), page: 1 })
                  }
                />
              </Stack>
              <PermissionCheckHOC
                WrappedComponent={UIButton}
                props={{
                  color: 'secondary',
                  variant: 'outlined',
                  loading: isDownloadEmployeeNumberLoading,
                  onClick: handleDownloadExcel,
                  disabled: !data?.data.length || !hasPermission,
                  children: t('excel_download'),
                  sx: { whiteSpace: 'nowrap' },
                }}
              />
            </Stack>
          </Stack>
          <Stack direction='row' mt={2} mb={2}>
            <Typography variant='h6' color={Colors.SECONDARY}>
              {t('summary_total_trips')}: {data?.totalCount ?? 0}
            </Typography>
          </Stack>
          <UITable
            data={data?.data ?? []}
            EmptyPlaceholder={<EmptyPlaceholder />}
            isLoading={isFetching}
            columns={[
              {
                label: '№',
                render: (_row, index) =>
                  (filterParams.pageSize ?? 10) * ((filterParams?.page ?? 1) - 1) + index + 1,
                nowrap: true,
              },
              {
                label: t('employees_number_table.performer'),
                render: ({ fullname, pinfl }) => (
                  <Stack direction='column'>
                    <Typography component='span' color={Colors.SECONDARY}>
                      {fullname}
                    </Typography>
                    <Typography component='span' color={Colors.BGRAY}>
                      {pinfl}
                    </Typography>
                  </Stack>
                ),
              },
              {
                label: `${t('employees_number_table.overall')}:`,
                render: ({ count }) => (
                  <Typography component='span' fontWeight='bold' color={Colors.SECONDARY}>
                    {count}
                  </Typography>
                ),
              },
            ]}
          />
          <Pagination
            count={data?.totalPages ?? 0}
            page={filterParams.page}
            onChange={(_e, page) => onChangeFilterParams({ page })}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          />
        </Stack>
      </ModalTemplate>
    </Dialog>
  );
});
