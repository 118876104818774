import { FC, useRef, useState } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { UIChip } from '@/shared/ui/ui-chip';
import { numericFormatter } from 'react-number-format';
import { UITable } from '@/shared/ui/ui-table';
import { UIActionButton } from '../../../shared/ui/ui-action-button';
import ConfirmModal from '../../../shared/confirm-modal';
import { useDeleteRowPaymentRegisterMutation } from '../../../app/api';
import notify from '../../../shared/toaster/lib/notify';

import { CloseIconWarning, TickIcon } from '@/shared/icons';

import { Colors } from '@/app/constants/colors.ts';
import { useTranslation } from 'react-i18next';
import { PaymentRegisterRow } from '@/app/api/model';
import { UIChipProps } from '@/shared/ui/ui-chip/types.ts';
import { ConfirmModalRef } from '../../../shared/confirm-modal/types';

interface MutualSettlementsPaymentRegisterTableProps {
  data: PaymentRegisterRow[];
  errors: Record<string | number, string>;
  onClickEditRow: (row: {
    unique: number;
    pinfl: string;
    cardNumber: string;
    cardOwner: string;
    amount: number;
  }) => void;
  isLoading: boolean;
  isPosted: boolean;
}

const IconComponent: FC<{ isFilled: boolean; tooltip: string }> = ({ isFilled, tooltip }) => {
  let Comp = null;
  if (isFilled) {
    Comp = (
      <Tooltip title={tooltip}>
        <Box component='span'>
          <Box component={TickIcon} sx={{ width: '24px' }} />
        </Box>
      </Tooltip>
    );
  } else
    Comp = (
      <Tooltip title={tooltip}>
        <Box component='span'>
          <Box component={CloseIconWarning} sx={{ width: '24px' }} />
        </Box>
      </Tooltip>
    );

  return Comp;
};

export const PaymentRegisterTable: FC<MutualSettlementsPaymentRegisterTableProps> = ({
  data,
  errors,
  onClickEditRow,
  isLoading,
  isPosted,
}) => {
  const { t } = useTranslation();
  const [rowsToDelete, setRowsToDelete] = useState<number[]>([]);

  const confirmModalRef = useRef<ConfirmModalRef | null>(null);

  const [deleteRegisterRow] = useDeleteRowPaymentRegisterMutation();

  const onClickDeleteRow = async (unique: number) => {
    try {
      setRowsToDelete((prev) => [...prev, unique]);
      await confirmModalRef.current?.init({
        title: t('toast.warning'),
        text: t('delete_register_modal'),
        btnText: t('yes_delete'),
      });
      await deleteRegisterRow({ unique });
      notify(t('toast.success'), 'success');
    } finally {
      setRowsToDelete((prev) => prev.filter((row) => row !== unique));
    }
  };

  const getPaymentStatusChipProps = (errorCode: number | string, paid: boolean): UIChipProps => {
    if (!paid && !errorCode) {
      return {
        color: 'gray',
        label: t('payment_registers_status.not_sent'),
      };
    }

    if (!paid && errorCode) {
      return {
        color: 'warning',
        label: errors[errorCode] ? errors[errorCode] : t('registry_error_statuses.unknown_error'),
      };
    }

    if (paid) {
      return {
        color: 'success',
        label: t('payment_registers_status.sent'),
      };
    }

    return {
      color: 'gray',
      label: t('registry_error_statuses.unknown_error'),
    };
  };

  return (
    <>
      <UITable
        data={data ?? []}
        isLoading={isLoading}
        EmptyPlaceholder={
          <Stack spacing={2.5} alignItems='center' justifyContent='center'>
            <EmptyPlaceholder
              title={t('payment_registry_page.no_data')}
              subtitle={t('no_payment_registers_text')}
            />
          </Stack>
        }
        columns={[
          {
            label: '№',
            render: ({ index }) => index,
            nowrap: true,
          },
          {
            label: t('performer_statuses'),
            render: ({
              userSelfEmployed,
              yourCommitent,
              aggregatorSubComitee,
              userNotFound,
              cardNotFound,
            }) => {
              if (userNotFound) {
                return (
                  <Typography variant='body2' color={Colors.ERROR}>
                    {t('register_tabs.not_in_system')}
                  </Typography>
                );
              }

              if (cardNotFound) {
                return (
                  <Typography variant='body2' color={Colors.ERROR}>
                    {t('register_tabs.card_not_found')}
                  </Typography>
                );
              }

              return (
                <Stack direction='row' alignItems='center' spacing={2}>
                  {[
                    {
                      isFilled: userSelfEmployed,
                      tooltip: t('payment_registry_page.self_employed'),
                    },
                    {
                      isFilled: yourCommitent,
                      tooltip: t('payment_registry_page.your_commitent'),
                    },
                    {
                      isFilled: aggregatorSubComitee,
                      tooltip: t('payment_registry_page.aggregator_subcomitee'),
                    },
                  ].map((i) => (
                    <IconComponent key={i.tooltip} {...i} />
                  ))}
                </Stack>
              );
            },
          },
          {
            label: t('payment_statuses'),
            render: ({ errorCode, paid }) => (
              <UIChip {...getPaymentStatusChipProps(errorCode, paid)} />
            ),
          },
          {
            label: t('customer_trips_page.employee'),
            render: ({ userName, userPinfl }) => (
              <Stack direction='column'>
                <Typography variant='body2' color={Colors.SECONDARY}>
                  {userName}
                </Typography>
                <Typography variant='body2' color={Colors.BGRAY}>
                  {userPinfl}
                </Typography>
              </Stack>
            ),
          },
          {
            label: t('card_number'),
            render: ({ cardNumber, cardOwner }) => {
              return (
                <Stack direction='column'>
                  <Typography variant='body2' color={Colors.SECONDARY}>
                    {cardNumber || '-'}
                  </Typography>
                  <Typography variant='body2' color={Colors.BGRAY}>
                    {cardOwner || '-'}
                  </Typography>
                </Stack>
              );
            },
          },
          {
            label: t('sum'),
            render: ({ amount }) => (
              <Stack direction='row' spacing={1.5}>
                <Typography
                  variant='body1'
                  fontWeight={600}
                  lineHeight='2.4rem'
                  whiteSpace='nowrap'
                  color={Colors.SECONDARY}
                >
                  {numericFormatter((amount / 100).toFixed(2), {
                    thousandSeparator: ' ',
                  })}{' '}
                  {t('currency_uzs')}
                </Typography>
              </Stack>
            ),
          },
          {
            label: '',
            render: ({ unique, userPinfl, cardNumber, cardOwner, amount }) => (
              <Stack direction='row'>
                <Tooltip
                  title={
                    isPosted ? t('payment_registry_page.can_not_use_if_registry_is_posted') : ''
                  }
                >
                  <Box component='span'>
                    <UIActionButton
                      icon='edit'
                      onClick={() =>
                        onClickEditRow({
                          pinfl: userPinfl,
                          cardNumber,
                          cardOwner,
                          amount,
                          unique,
                        })
                      }
                      disabled={isPosted}
                    />
                  </Box>
                </Tooltip>

                <Tooltip
                  title={
                    isPosted ? t('payment_registry_page.can_not_use_if_registry_is_posted') : ''
                  }
                >
                  <Box component='span'>
                    <UIActionButton
                      icon='delete'
                      onClick={() => onClickDeleteRow(unique)}
                      disabled={rowsToDelete.includes(unique) || isPosted}
                    />
                  </Box>
                </Tooltip>
              </Stack>
            ),
          },
        ]}
      />
      <ConfirmModal ref={confirmModalRef} />
    </>
  );
};
