import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthCustomerPayload, AuthEmployeePayload, AuthState } from '.';
import { setCabinetType } from '@/entities/app/model/slice';
import Cookies from 'js-cookie';
import { TaxiCompany } from '@/app/api/model';

const company = localStorage.getItem('company');
const userData = localStorage.getItem('userData');
const selectedTaxiCompany = localStorage.getItem('selectedTaxiCompany');
const isPassLog = localStorage.getItem('isPassLog') === 'true' ? true : false;

const initialState: AuthState = {
  authorized: !!Cookies.get('token') && (!!company || !!userData),
  company: company ? JSON.parse(company || '{}') : undefined,
  selectedTaxiCompany: selectedTaxiCompany ? JSON.parse(selectedTaxiCompany || '{}') : undefined,
  userData: userData ? JSON.parse(userData || '{}') : undefined,
  roles: JSON.parse(company || '{}')['roles'] ?? [],
  isPassLog,
};

export const authCustomer = createAsyncThunk(
  'auth/authCustomer',
  (payload: AuthCustomerPayload, { dispatch }) => {
    dispatch(setCabinetType('customer'));
    return payload;
  }
);

export const authEmployee = createAsyncThunk(
  'auth/authEmployee',
  (payload: AuthEmployeePayload, { dispatch }) => {
    dispatch(setCabinetType('employee'));
    return payload;
  }
);

export const logout = createAsyncThunk('auth/logout', (_, { dispatch }) => {
  dispatch(setCabinetType(undefined));

  return;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorized: (state, payload: PayloadAction<boolean>) => {
      state.authorized = payload.payload;
    },
    setSelectedTaxiCompany: (state, payload: PayloadAction<TaxiCompany>) => {
      state.selectedTaxiCompany = payload.payload;
      localStorage.setItem('selectedTaxiCompany', JSON.stringify(payload.payload));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authCustomer.fulfilled, (state, action) => {
      state.authorized = true;
      state.company = action.payload.company;
      Cookies.set('token', action.payload.token, { expires: 1, secure: true });
      Cookies.set('user-key', action.payload.userKey, {
        expires: 1,
        secure: true,
      });
      localStorage.setItem('company', JSON.stringify(action.payload.company));
      localStorage.setItem('isPassLog', String(action.payload.isPassLog));
      state.roles = action.payload.company.roles;
      state.isPassLog = action.payload.isPassLog;
    });
    builder.addCase(authEmployee.fulfilled, (state, action) => {
      state.authorized = true;
      state.userData = action.payload.userData;
      Cookies.set('token', action.payload.token, { expires: 1, secure: true });
      localStorage.setItem('userData', JSON.stringify(action.payload.userData));
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.authorized = false;
      state.token = undefined;
      state.company = undefined;
      state.userData = undefined;
      state.selectedTaxiCompany = undefined;
      state.isPassLog = false;
      localStorage.removeItem('isPassLog');
      localStorage.removeItem('company');
      localStorage.removeItem('userData');
      localStorage.removeItem('selectedTaxiCompany');
      Cookies.remove('token');
      Cookies.remove('user-key');
    });
  },
});

export const { setAuthorized, setSelectedTaxiCompany } = authSlice.actions;
