import { SyntheticEvent, useMemo, useState } from 'react';
import { Layout } from '@/shared/layout';
import UITabs from '@/shared/ui/ui-tabs';
import { Stack, Typography } from '@mui/material';
import { MutualSettlementsDrivers } from './ui/mutual-settlements-drivers';
import { UISection } from '@/shared/ui/ui-section';
import { MutualSettlementsTransactions } from './ui/mutual-settlements-transactions';
import { MutualSettlementsPaymentRegisters } from './ui/mutual-settlements-payment-registers';
import { numericFormatter } from 'react-number-format';
import { getSumFromTiins } from '@/app/helpers';
import { useAppSelector } from '../../app/hooks';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';
import { useSearchParams } from 'react-router-dom';

export const CustomerMutualSettlementsPage = () => {
  const { t } = useTranslation();
  const { company } = useAppSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(Number(searchParams.get('activeTab')));
  const [totalSum, setTotalSum] = useState(0);

  const handleTabChange = (_event: SyntheticEvent<Element, Event>, newValue: number) => {
    setActiveTab(newValue);
    setSearchParams({ activeTab: newValue.toString() });
  };

  const mutualSettlementsTabs = useMemo(() => {
    let tabs: { label: string; component: JSX.Element }[] = [];
    switch (company?.invitationFlow) {
      case 'plovme':
        tabs = [
          {
            label: t('transfers'),
            component: <MutualSettlementsTransactions setTotalSum={setTotalSum} />,
          },
        ];
        break;
      case 'uzum':
      case 'wolt':
      case 'uklon':
        tabs = [
          {
            label: t('drivers'),
            component: <MutualSettlementsDrivers />,
          },
          {
            label: t('transfers'),
            component: <MutualSettlementsTransactions setTotalSum={setTotalSum} />,
          },
          {
            label: t('payment_registers'),
            component: <MutualSettlementsPaymentRegisters />,
          },
        ];
        break;
      default:
        tabs = [
          {
            label: t('drivers'),
            component: <MutualSettlementsDrivers />,
          },
          {
            label: t('transfers'),
            component: <MutualSettlementsTransactions setTotalSum={setTotalSum} />,
          },
        ];
    }

    return tabs;
  }, [company]);

  return (
    <Layout>
      <Stack spacing={2}>
        {!!totalSum && (
          <UISection>
            <Stack direction='row' alignItems='baseline' spacing={1.5}>
              <Typography variant='h6' color={Colors.SUCCESS}>
                {t('total')}:
              </Typography>
              <Typography variant='body1' fontWeight={600} fontSize={'2rem'}>
                {numericFormatter(String(getSumFromTiins(totalSum, false).toFixed(2)), {
                  thousandSeparator: ' ',
                })}{' '}
                UZS
              </Typography>
            </Stack>
          </UISection>
        )}

        <UISection sx={{ pt: 1, height: '100%' }}>
          <UITabs value={activeTab} onChange={handleTabChange} tabs={mutualSettlementsTabs} />
        </UISection>
      </Stack>
    </Layout>
  );
};
