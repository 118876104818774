import { useMemo, useRef, useState } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { PageSizeSelect } from '@/shared/page-size-select';
import { UIActionButton } from '@/shared/ui/ui-action-button';
import { UIButton } from '@/shared/ui/ui-button';
import { UITable } from '@/shared/ui/ui-table';
import { useForm } from 'react-hook-form';
import { SettlementsCreateTransactionModal } from './settlements-create-transaction-modal';
import { ImportYandexDriversModal } from './import-yandex-drivers-modal';
import { ImportYandexDriversHistoryModal } from './import-yandex-drivers-histroy-modal';
import { UISwitch } from '@/shared/ui/ui-switch';
import PermissionCheckHOC from '../../../shared/permission-hoc';
import {
  useChangeAccessToWithdrawalMutation,
  useGetDriversListQuery,
  useLazyGetImportWorkersFromYandexActiveDriversCountQuery,
} from '@/app/api';
import { useAppSelector, useFilters } from '@/app/hooks';

import { useTranslation } from 'react-i18next';
import { SettlementsCreateTransferModalRef } from './types';
import { BaseModalRef, checkIfHasPermission } from '@/app/helpers';

interface FormValues {
  search: string;
}

interface SwitchValues {
  [key: number]: boolean;
}

export const MutualSettlementsDrivers = () => {
  const { t } = useTranslation();
  const { company, roles } = useAppSelector((state) => state.auth);
  const { control, handleSubmit } = useForm<FormValues>({ defaultValues: { search: '' } });
  const [isLoadingArray, setIsLoadingArray] = useState<number[]>([]);
  const [switchValues, setSwitchValues] = useState<SwitchValues>({});

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      pageSize: 10,
      search: '',
    },
  });

  const { data, isFetching } = useGetDriversListQuery(filterParams, {
    refetchOnMountOrArgChange: true,
  });
  const [getImportWorkersCount, { isFetching: isYandexImportCountFetching }] =
    useLazyGetImportWorkersFromYandexActiveDriversCountQuery();
  const [changeAccessToWithdrawal] = useChangeAccessToWithdrawalMutation();

  const modalRef = useRef<SettlementsCreateTransferModalRef>(null);
  const importYandexModalRef = useRef<BaseModalRef>(null);
  const importYandexHistoryModalRef = useRef<BaseModalRef>(null);

  const onImportYandexModalOpen = async () => {
    await getImportWorkersCount({}).unwrap();
    importYandexModalRef.current?.open();
  };

  const onSubmitHandler = (data: FormValues) => {
    onChangeFilterParams({ search: data.search, page: 1 });
  };

  const onChangeAccessToWithdrawal = async (unique: number, accessToWithdrawal: boolean) => {
    try {
      setIsLoadingArray((prev) => [...prev, unique]);
      await changeAccessToWithdrawal({ unique, accessToWithdrawal }).unwrap();
      setSwitchValues((prev) => ({ ...prev, [unique]: accessToWithdrawal }));
    } finally {
      setIsLoadingArray((prev) => prev.filter((item) => item !== unique));
    }
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18732']), [roles]);

  return (
    <Stack spacing={2}>
      <Stack direction='row' justifyContent='space-between' spacing={1.5}>
        {company?.invitationFlow === 'yandex' && (
          <Stack direction='row' spacing={1.5}>
            <Tooltip
              title={
                isFetching
                  ? ''
                  : data?.importWorkersFromYandexApiIsOk
                  ? ''
                  : t('fill_yandex_api_tooltip')
              }
            >
              <Box component='span'>
                <PermissionCheckHOC
                  WrappedComponent={UIButton}
                  props={{
                    onClick: onImportYandexModalOpen,
                    loading: isYandexImportCountFetching,
                    disabled: !data?.importWorkersFromYandexApiIsOk || !hasPermission,
                    showTooltip: !!data?.importWorkersFromYandexApiIsOk,
                    children: t('upload_from_yandex_go'),
                  }}
                />
              </Box>
            </Tooltip>
            <UIButton
              variant='outlined'
              color='secondary'
              onClick={() => {
                importYandexHistoryModalRef.current?.open();
              }}
            >
              {t('import_history')}
            </UIButton>
          </Stack>
        )}

        <Stack
          component='form'
          direction='row'
          spacing={1.5}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <FormTextInput control={control} name='search' label={t('search')} />
          <UIButton type='submit'>{t('to_find')}</UIButton>
          <PageSizeSelect
            value={filterParams.pageSize}
            onChange={({ target }) =>
              onChangeFilterParams({ pageSize: Number(target.value), page: 1 })
            }
          />
        </Stack>
      </Stack>

      <UITable
        data={data?.data ?? []}
        EmptyPlaceholder={<EmptyPlaceholder />}
        isLoading={isFetching}
        columns={[
          {
            label: '№',
            render: (_row, index) => filterParams.pageSize * (filterParams.page - 1) + index + 1,
            nowrap: true,
          },
          { label: t('fio'), key: 'name' },
          { label: t('phone_number'), key: 'phone' },
          { label: t('driver_license'), key: 'driverLicense' },
          {
            label: t('car_number'),
            render: ({ carNumber = '' }) =>
              carNumber.replace(/(\d{2})([A-Z]{1})(\d{3})([A-Z]{2})/, '$1 $2 $3 $4'),
          },
          { label: t('pinfl'), key: 'pinfl' },
          {
            label: t('actions'),
            render: (row) => (
              <Stack direction='row' spacing={1}>
                <Tooltip title={!hasPermission ? '' : t('transfer_to_driver')}>
                  <Box component='span'>
                    <PermissionCheckHOC
                      WrappedComponent={UIActionButton}
                      props={{
                        icon: 'card-send',
                        onClick: () => {
                          modalRef.current?.init({
                            unique: row.unique,
                            driverName: row.name,
                          });
                        },
                        disabled: !hasPermission,
                      }}
                    />
                  </Box>
                </Tooltip>
              </Stack>
            ),
            nowrap: true,
          },
          {
            label: t('withdrawal'),
            render: (row) => (
              <PermissionCheckHOC
                WrappedComponent={UISwitch}
                props={{
                  checked: switchValues[row.unique] ?? row.accessToWithdrawal,
                  onChange: (_event, checked) => onChangeAccessToWithdrawal(row.unique, checked),
                  disabled: isLoadingArray.includes(row.unique) || !hasPermission,
                }}
              />
            ),
            headerAlign: 'right',
            align: 'right',
          },
        ]}
        PaginationProps={{
          count: data?.totalPages ?? 0,
          page: filterParams.page,
          onChange: (_e, page) => onChangeFilterParams({ page }),
        }}
      />
      <SettlementsCreateTransactionModal ref={modalRef} />
      <ImportYandexDriversModal ref={importYandexModalRef} mode='settlements' />
      <ImportYandexDriversHistoryModal ref={importYandexHistoryModalRef} />
    </Stack>
  );
};
