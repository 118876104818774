import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Pagination, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { UIButton } from '@/shared/ui/ui-button';
import { useForm } from 'react-hook-form';
import { PageSizeSelect } from '@/shared/page-size-select';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { useFilters } from '@/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';
import UITabs from '@/shared/ui/ui-tabs';
import { Layout } from '@/shared/layout';
import { PaymentRegisterModal } from '@/pages/payment-registry-page/ui/payment-register-modal';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import ConfirmModal from '@/shared/confirm-modal';
import { UILoader } from '@/shared/ui/ui-loader';
import { UIChip } from '@/shared/ui/ui-chip';
import { PaymentRegisterTable } from './ui/payment-register-table';
import { ImportRegistryModal } from './ui/import-registry-payment-modal';
import {
  useCheckBalanceQuery,
  useGetPaymentRegistryQuery,
  useLazyDownloadPaymentRegisterExcelQuery,
  usePostRegistryMutation,
} from '@/app/api';
import { useTableData } from './helpers';
import notify from '@/shared/toaster/lib/notify.tsx';

import { WarningAmber } from '@/shared/icons';

import { Trans, useTranslation } from 'react-i18next';
import { UIChipProps } from '@/shared/ui/ui-chip/types.ts';
import { ConfirmModalRef } from '@/shared/confirm-modal/types.ts';
import { PaymentRegistryModalRef } from './types';
import { Colors } from '../../app/constants/colors';
import { BaseModalRef } from '../../app/helpers';

interface FilterFormValues {
  search: string;
}

interface FilterParams {
  page: number;
  pageSize: number;
  search: string;
  userNotFoundFilter: boolean;
  wrongDataFilter: boolean;
}

export const MutualSettlementsPaymentRegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { unique } = useParams<{ unique: string }>();
  const [counterFilter, setCounterFilter] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  const { control: filterControl, handleSubmit: filterHandleSubmit } = useForm<FilterFormValues>({
    defaultValues: { search: '' },
  });

  const { filterParams, onChangeFilterParams } = useFilters<FilterParams>({
    initialFilterParams: {
      page: 1,
      pageSize: 10,
      search: '',
      userNotFoundFilter: false,
      wrongDataFilter: false,
    },
  });

  const confirmModalRef = useRef<ConfirmModalRef | null>(null);
  const paymentRegisterModalRef = useRef<PaymentRegistryModalRef>(null);
  const importRegistryModalRef = useRef<BaseModalRef>(null);

  const { data: balanceData } = useCheckBalanceQuery({});

  const { data, isLoading, refetch } = useGetPaymentRegistryQuery(
    { ...filterParams, unique: Number(unique) },
    { skip: !unique }
  );
  const [postRegistry, { isLoading: isPostRegistryLoading }] = usePostRegistryMutation();
  const [downloadPayment, { isLoading: isDownloadPaymentLoading }] =
    useLazyDownloadPaymentRegisterExcelQuery();

  const { rows, updateRow } = useTableData(data?.rows);
  const { restrictionInfo, registryTotalSum, availableBalance } = useMemo(() => {
    const registryTotalSum = data?.totalSum ?? 0;
    const availableBalance = balanceData?.availableBalance ?? 0;

    return {
      registryTotalSum,
      availableBalance,
      restrictionInfo: availableBalance > 0 && availableBalance >= registryTotalSum,
    };
  }, [data, balanceData]);

  useEffect(() => {
    if (!filterParams.userNotFoundFilter && !filterParams.wrongDataFilter) {
      setTotalElements(data?.elements ?? 0);
    }
  }, [data]);

  const statusColors: Record<string, UIChipProps['color']> = {
    draft: 'purple',
    done: 'success',
    partially: 'warning',
    inProgress: 'info',
    default: 'gray',
  };

  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const onSubmitHandler = (data: FilterFormValues) => {
    onChangeFilterParams({ search: data.search, page: 1 });
  };

  const getStatus = (status: string): UIChipProps => {
    const color = statusColors[status] || statusColors.default;

    return {
      color,
      label: t(`payment_registers_status.${status}`),
    };
  };

  const onClickAddRow = () => {
    if (!unique) return;
    paymentRegisterModalRef?.current?.init({ mode: 'add', unique: Number(unique) });
  };

  const onClickEditRow = (row: {
    unique: number;
    pinfl: string;
    cardNumber: string;
    cardOwner: string;
    amount: number;
  }) => {
    paymentRegisterModalRef?.current?.init({
      mode: 'edit',
      ...row,
    });
  };

  const onClickPostRegistry = async () => {
    if (!rows.length) {
      notify(t('payment_registry_page.no_even_one_row'), 'error');
      return;
    }
    await confirmModalRef.current?.init({
      title: `${t('toast.warning')}!`,
      cancelBtnText: t('to_reject'),
      text: (
        <Trans
          i18nKey='payment_registry_page.confirm_post_report'
          components={{
            1: <Box component='span' sx={{ display: 'block', my: 2 }} />,
            2: (
              <Box
                component='span'
                sx={{
                  fontWeight: 'bold',
                }}
              />
            ),
          }}
        />
      ),
      textStyle: {
        fontSize: '1.6rem',
      },
    });
    await postRegistry({ unique: Number(unique) }).unwrap();
    notify(t('toast.success'), 'success');
  };

  const downloadExcel = async () => {
    await downloadPayment({ unique: Number(unique) }).unwrap();
    notify(t('toast.success'), 'success');
  };

  const handleOnClickBack = () => {
    navigate(-1);
  };

  const onImportRegistryRows = () => {
    importRegistryModalRef.current?.open();
  };

  const onChangeTabHandle = (_event: SyntheticEvent<Element, Event>, value: number) => {
    setCounterFilter(value);
    switch (value) {
      case 0:
        onChangeFilterParams({
          userNotFoundFilter: false,
          wrongDataFilter: false,
        });
        break;
      case 1:
        onChangeFilterParams({ userNotFoundFilter: true, wrongDataFilter: false });
        break;
      case 2:
        onChangeFilterParams({ userNotFoundFilter: false, wrongDataFilter: true });
        break;
    }
  };

  const activeTab = filterParams.userNotFoundFilter ? 1 : filterParams.wrongDataFilter ? 2 : 0;

  const errors: Record<number | string, string> = useMemo(
    () => ({
      '-32310': t('registry_error_statuses.card_is_blocked'),
      '-32336': t('registry_error_statuses.card_expired'),
      '-32302': t('registry_error_statuses.card_info_unavailable'),
      '1020': t('registry_error_statuses.terminal_not_found'),
      '-32335': t('registry_error_statuses.card_not_found'),
      '1017': t('registry_error_statuses.card_number_invalid'),
      '-32339': t('registry_error_statuses.humo_service_unavailable'),
      '-32463': t('registry_error_statuses.card_lost'),
      '-32406': t('registry_error_statuses.processing_error'),
      '-53': t('registry_error_statuses.insufficient_funds'),
      '-420': t('registry_error_statuses.payment_already_confirmed'),
    }),
    [t]
  );

  const isPosted = data?.status !== 'draft';

  if (isLoading) {
    return (
      <Layout>
        <UILoader size={40} />
      </Layout>
    );
  }

  return (
    <Layout>
      <Stack
        sx={{
          backgroundColor: Colors.WHITE,
          padding: '24px',
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      >
        <Stack direction='column'>
          <Stack direction='row' display='flex' alignItems='center' spacing={2} mb={4}>
            <UIButton color='inherit' onClick={handleOnClickBack}>
              {t('back')}
            </UIButton>

            <Typography variant='h6' alignItems='center' sx={{ color: Colors.BGRAY }}>
              <Box component='span' sx={{ color: Colors.SECONDARY, fontWeight: 'bold' }}>
                {data?.name}
              </Box>
            </Typography>
          </Stack>

          <Stack direction={isTablet ? 'row' : 'column'} justifyContent='space-between'>
            <Stack direction='row' flexWrap='wrap' gap={4}>
              <Stack direction='column'>
                <Typography>{t('status')}:</Typography>
                {isLoading ? (
                  <Box>
                    <UILoader size={20} />
                  </Box>
                ) : (
                  <UIChip {...getStatus(data?.status ?? '')} />
                )}
              </Stack>
              <Stack direction='column'>
                <Typography
                  sx={{
                    whiteSpace: 'none',
                  }}
                >
                  {t('register_total_balance_rows')}:
                </Typography>
                {isLoading ? (
                  <Box>
                    <UILoader size={20} />
                  </Box>
                ) : (
                  <Typography
                    variant='h6'
                    sx={{
                      whiteSpace: 'none',
                    }}
                  >
                    {numericFormatter(String((registryTotalSum / 100).toFixed(2)), {
                      thousandSeparator: ' ',
                    })}
                  </Typography>
                )}
              </Stack>
              <Stack direction='column' color={Colors.BGRAY}>
                <Typography
                  sx={{
                    whiteSpace: 'none',
                  }}
                >
                  {t('register_total_balance')}:
                </Typography>
                {isLoading ? (
                  <Box>
                    <UILoader size={20} />
                  </Box>
                ) : (
                  <Typography
                    variant='h6'
                    sx={{
                      whiteSpace: 'none',
                    }}
                  >
                    {numericFormatter(String((availableBalance / 100).toFixed(2)), {
                      thousandSeparator: ' ',
                    })}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <UIButton
              variant='contained'
              color='primary'
              onClick={onClickPostRegistry}
              loading={isPostRegistryLoading}
              disabled={!restrictionInfo || isPosted}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {t('conduct_register_payments')}
            </UIButton>
          </Stack>
        </Stack>
      </Stack>

      {!restrictionInfo && (
        <Stack
          direction='row'
          alignItems='center'
          sx={{ background: '#FFF4E5', padding: '14px 16px', borderRadius: '4px' }}
          mb={2}
        >
          <WarningAmber style={{ width: '50px' }} />
          <Typography color='#663C00' ml={2}>
            {t('payment_restriction')}
          </Typography>
        </Stack>
      )}

      <Box
        sx={{
          backgroundColor: Colors.WHITE,
          padding: '24px',
          borderRadius: '8px',
        }}
      >
        <Stack
          direction={isTablet ? 'row' : 'column'}
          justifyContent='space-between'
          spacing={1.5}
          mb={4}
        >
          <Stack direction='row' flexWrap='wrap' gap={2}>
            {!rows.length &&
              !filterParams.userNotFoundFilter &&
              !filterParams.wrongDataFilter &&
              data?.totalSum === 0 && (
                <UIButton
                  onClick={onImportRegistryRows}
                  disabled={isPosted}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {t('import_register_from_file')}
                </UIButton>
              )}
            <UIButton
              variant='outlined'
              color='secondary'
              onClick={onClickAddRow}
              disabled={isPosted}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {t('add_registers_manually')}
            </UIButton>
            {(data?.status === 'partially' || data?.status === 'done') && (
              <UIButton
                variant='text'
                color='secondary'
                onClick={downloadExcel}
                loading={isDownloadPaymentLoading}
              >
                {t('download_file')}
              </UIButton>
            )}
          </Stack>

          <Stack
            component='form'
            direction='row'
            flexWrap='wrap'
            gap={2}
            onSubmit={filterHandleSubmit(onSubmitHandler)}
          >
            <Stack direction='row' spacing={1}>
              <FormTextInput control={filterControl} name='search' label={t('fio')} />

              <UIButton type='submit'>{t('to_find')}</UIButton>
            </Stack>

            <PageSizeSelect
              value={filterParams.pageSize}
              onChange={({ target }) =>
                onChangeFilterParams({ pageSize: Number(target.value), page: 1 })
              }
            />
          </Stack>
        </Stack>

        {!rows.length && !filterParams.userNotFoundFilter && !filterParams.wrongDataFilter ? (
          <Stack spacing={2.5} alignItems='center' justifyContent='center' my={10}>
            <EmptyPlaceholder
              title={t('payment_registry_page.no_data')}
              subtitle={t('no_payment_registers_text')}
            />
            {data?.totalSum === 0 && (
              <UIButton onClick={onImportRegistryRows}>{t('import_register_from_file')}</UIButton>
            )}
          </Stack>
        ) : (
          <>
            <UITabs
              value={activeTab}
              onChange={onChangeTabHandle}
              tabs={[
                {
                  label: (
                    <Stack direction='row' alignItems='center'>
                      {t('register_tabs.all')}

                      <Typography
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        variant='body2'
                        sx={{
                          width: `${Math.max(
                            35,
                            20 + (totalElements ?? 0).toString().length * 8
                          )}px`,
                          borderRadius: '20px',
                          marginLeft: '8px',
                          color: counterFilter === 0 ? Colors.WHITE_TEXT : Colors.SECONDARY,
                          backgroundColor: counterFilter === 0 ? Colors.ACCENT : Colors.LGRAY,
                          transition: 'color 0.3s ease, background-color 0.3s ease',
                        }}
                      >
                        {totalElements ?? 0}
                      </Typography>
                    </Stack>
                  ),
                  component: (
                    <PaymentRegisterTable
                      data={rows ?? []}
                      errors={errors}
                      onClickEditRow={onClickEditRow}
                      isLoading={isLoading}
                      isPosted={isPosted}
                    />
                  ),
                },
                {
                  label: (
                    <Stack direction='row' alignItems='center'>
                      {t('register_tabs.not_in_system')}
                      <Typography
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        variant='body2'
                        sx={{
                          width: `${Math.max(
                            35,
                            20 + (data?.userNotFoundCount ?? 0).toString().length * 8
                          )}px`,
                          borderRadius: '20px',
                          marginLeft: '8px',
                          color: counterFilter === 1 ? Colors.WHITE_TEXT : Colors.SECONDARY,
                          backgroundColor: counterFilter === 1 ? Colors.ACCENT : Colors.LGRAY,
                          transition: 'color 0.3s ease, background-color 0.3s ease',
                        }}
                      >
                        {data?.userNotFoundCount ?? 0}
                      </Typography>
                    </Stack>
                  ),
                  component: (
                    <PaymentRegisterTable
                      data={rows ?? []}
                      errors={errors}
                      onClickEditRow={onClickEditRow}
                      isLoading={isLoading}
                      isPosted={isPosted}
                    />
                  ),
                },
                {
                  label: (
                    <Stack direction='row' alignItems='center'>
                      {t('register_tabs.wrong_data')}
                      <Typography
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        variant='body2'
                        sx={{
                          width: `${Math.max(
                            35,
                            20 + (data?.wrongDataCount ?? 0).toString().length * 8
                          )}px`,
                          borderRadius: '20px',
                          marginLeft: '8px',
                          color: counterFilter === 2 ? Colors.WHITE_TEXT : Colors.SECONDARY,
                          backgroundColor: counterFilter === 2 ? Colors.ACCENT : Colors.LGRAY,
                          transition: 'color 0.3s ease, background-color 0.3s ease',
                        }}
                      >
                        {data?.wrongDataCount ?? 0}
                      </Typography>
                    </Stack>
                  ),
                  component: (
                    <PaymentRegisterTable
                      data={rows ?? []}
                      errors={errors}
                      onClickEditRow={onClickEditRow}
                      isLoading={isLoading}
                      isPosted={isPosted}
                    />
                  ),
                },
              ]}
            />

            <Pagination
              count={data?.totalPages ?? 0}
              page={filterParams.page}
              onChange={(_e, page) => onChangeFilterParams({ page })}
              sx={{
                display: !rows.length ? 'none' : 'flex',
                justifyContent: 'center',
              }}
            />
          </>
        )}
      </Box>

      <ConfirmModal ref={confirmModalRef} />
      <PaymentRegisterModal ref={paymentRegisterModalRef} updateRow={updateRow} refetch={refetch} />
      <ImportRegistryModal ref={importRegistryModalRef} unique={unique} />
    </Layout>
  );
};
