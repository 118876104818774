import { useEffect, useState } from 'react';

import { EditPaymentRegisterRowBody, PaymentRegisterRow } from '../../app/api/model';

export const useTableData = (dataRows: PaymentRegisterRow[] = []) => {
  const [rows, setRows] = useState<PaymentRegisterRow[]>([]);

  useEffect(() => {
    setRows(dataRows);
  }, [dataRows]);

  const updateRow = (updatedRow: EditPaymentRegisterRowBody) => {
    setRows((prev) => {
      const updatedRows = prev.map((row) => {
        if (row.unique === updatedRow.unique) {
          return { ...row, ...updatedRow };
        }
        return row;
      });

      return updatedRows;
    });
  };

  return { rows, updateRow };
};
