import { forwardRef, useImperativeHandle, useState } from 'react';
import ModalTemplate from '@/shared/modal-template';
import { Dialog, Stack } from '@mui/material';
import { useLazyGetInfoByTinOrPinflQuery } from '@/app/api';
import { useForm } from 'react-hook-form';
import { YandexRowModalBody } from './yandex-row-modal-body';
import { UklonRowModalBody } from './uklon-row-modal-body';
import { UIButton } from '../../../shared/ui/ui-button';
import { useOpen } from '@/app/hooks';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import {
  ChangeRowModalInitBody,
  ChangeRowModalRef,
  CustomYandexReportRowInterface,
  ChangeYandexRowModalFormValues,
} from '../types';
import { UklonReportRow } from '../../../app/api/model';
import { useReportsContext } from '../context';

export const ChangeRowModal = forwardRef<ChangeRowModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const {
    form,
    isTravelReport,
    isRewardReport,
    isYandex,
    isUklon,
    onClickUpdateYandexRow,
    onClickUpdateUklonRow,
  } = useReportsContext();
  const { open, setOpen } = useOpen();
  const [index, setIndex] = useState<number>();
  const [title, setTitle] = useState<string>();

  const yandexForm = useForm<ChangeYandexRowModalFormValues>();
  const uklonForm = useForm<UklonReportRow>();

  const [getInfoByTinOrPinfl, { isFetching }] = useLazyGetInfoByTinOrPinflQuery();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
      init,
    }),
    []
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    if (isYandex) {
      yandexForm.clearErrors();
      yandexForm.reset();
    }
    if (isUklon) {
      uklonForm.clearErrors();
      uklonForm.reset();
    }
    setIndex(undefined);
    setTitle('');
    setOpen(false);
  };

  const init = (body: ChangeRowModalInitBody) => {
    const { index, titleToSet, row } = body;
    onOpen();
    setIndex(index);
    setTitle(titleToSet);

    if (isYandex) {
      if (row) {
        yandexForm.reset(row as CustomYandexReportRowInterface);
      } else {
        const aggregatorPercent = form.getValues('aggregatorPercent');
        const taxiPercent = form.getValues('taxiPercent');
        yandexForm.reset({ aggregatorPercent, taxiPercent });
      }
    }

    if (isUklon) {
      if (row) {
        uklonForm.reset(row as UklonReportRow);
        if (!row.name) {
          onChangePersonalData(row.pinfl);
        }
      } else uklonForm.reset({ pinfl: '' });
    }
  };

  const onChangePersonalData = async (value: string | undefined) => {
    if (!value) {
      if (isYandex) return yandexForm.setValue('name', '');
      if (isUklon) return uklonForm.setValue('name', '');
    }

    if (value && (value.length === 9 || value.length === 14)) {
      const response = await getInfoByTinOrPinfl(value, true).unwrap();
      const name =
        response.fullName ??
        response.fullname ??
        response.shortName ??
        response.shortname ??
        response.name ??
        '';

      if (isYandex) yandexForm.setValue('name', name);
      if (isUklon) uklonForm.setValue('name', name);
      if (value.length === 9 && +value[0] > 3 && response.personalNum) {
        if (isYandex) yandexForm.setValue('pinfl', response.personalNum);
        if (isUklon) uklonForm.setValue('pinfl', response.personalNum);
      }
    }
  };

  const debounceOnChange = _.debounce(onChangePersonalData, 1500);

  const onSubmitYandexForm = (data: ChangeYandexRowModalFormValues) => {
    if (typeof index === 'number') {
      onClickUpdateYandexRow(index, data);
    }
    onClose();
  };

  const onSubmitUklonForm = (data: UklonReportRow) => {
    if (typeof index === 'number') {
      onClickUpdateUklonRow(index, data);
    }
    onClose();
  };

  const renderChangeRowModalBody = () => {
    if (isYandex) {
      return (
        <YandexRowModalBody
          yandexForm={yandexForm}
          isTravelReport={isTravelReport}
          isRewardReport={isRewardReport}
          debounceOnChange={debounceOnChange}
        />
      );
    }

    if (isUklon) {
      return <UklonRowModalBody uklonForm={uklonForm} debounceOnChange={debounceOnChange} />;
    }
  };

  if (typeof index === 'undefined') return null;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 560,
        },
      }}
    >
      <ModalTemplate title={title} onClose={onClose} noBtns>
        {renderChangeRowModalBody()}
      </ModalTemplate>
      <Stack direction='row' justifyContent='center' mb={4} spacing={1.5}>
        <UIButton color='inherit' onClick={onClose}>
          {t('cancel')}
        </UIButton>
        <UIButton
          onClick={
            isYandex
              ? yandexForm.handleSubmit(onSubmitYandexForm)
              : uklonForm.handleSubmit(onSubmitUklonForm)
          }
          disabled={isYandex ? !yandexForm.watch('name') : !uklonForm.watch('name')}
          loading={isFetching}
        >
          {t('save')}
        </UIButton>
      </Stack>
    </Dialog>
  );
});
