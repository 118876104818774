import { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Dialog, FormControl, FormHelperText } from '@mui/material';
import { FilePicker } from '@/shared/inputs/file-picker';
import ModalTemplate from '@/shared/modal-template';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { Link } from 'react-router-dom';
import { useImportPaymentRegisterFromExcelMutation } from '@/app/api';
import { BaseModalRef } from '@/app/helpers';
import { useOpen } from '@/app/hooks';
import notify from '@/shared/toaster/lib/notify';

import { useTranslation } from 'react-i18next';

interface ImportRegistryModalProps {
  unique: string | undefined;
}

export const ImportRegistryModal = forwardRef<BaseModalRef, ImportRegistryModalProps>(
  ({ unique }, ref) => {
    const { t } = useTranslation();
    const { open, setOpen } = useOpen();
    const [files, setFiles] = useState<File[]>([]);

    const errorMessageForFiles: Record<number | string, string> = {
      '-911': t('registry_error_statuses.file_format_unsupported'),
      '-912': t('registry_error_statuses.file_size_exceeded'),
    };

    const [importRegistry, { isLoading }] = useImportPaymentRegisterFromExcelMutation();

    useImperativeHandle(
      ref,
      () => ({
        open: onOpen,
        close: onClose,
      }),
      []
    );

    const onOpen = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
      setFiles([]);
    };

    const handleSubmit: React.FormEventHandler<HTMLDivElement> = async (e) => {
      e.preventDefault();

      if (!files.length || !unique) return;
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('unique', unique);

      try {
        await importRegistry(formData).unwrap();
        notify(t('toast.success'), 'success');
        onClose();
      } catch (err: any) {
        const errorCode = err?.data?.error?.code;
        const errorMessage = errorMessageForFiles[errorCode] || t('toast.error');
        notify(errorMessage, 'error');
      }
    };

    return (
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 560,
          },
        }}
      >
        <ModalTemplate
          title={t('upload_registry')}
          onClose={onClose}
          submitBtnText={t('upload')}
          loading={isLoading}
          onSubmit={handleSubmit}
        >
          <FormControl>
            <UIFormLabel>{t('attach_file')}</UIFormLabel>

            <FilePicker value={files} onChange={setFiles} accept='.xlsx, .xls' />
            <FormHelperText>
              {t('excel_upload_helper_text')}{' '}
              <Box
                component={Link}
                to={t('links:yandex_doc_registry_template')}
                target='_blank'
                download={'template.xlsx'}
              >
                {t('download_excel_template')}
              </Box>
            </FormHelperText>
          </FormControl>
        </ModalTemplate>
      </Dialog>
    );
  }
);
