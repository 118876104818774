import { useMemo, useRef, useState } from 'react';
import {
  Box,
  Pagination,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PermissionCheckHOC from '@/shared/permission-hoc';
import { UIButton } from '@/shared/ui/ui-button';
import { FormDatePicker } from '@/shared/inputs/form-date-picker';
import { PageSizeSelect } from '@/shared/page-size-select';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormSelectInput from '@/shared/inputs/form-autocomplete-input';
import { checkIfHasPermission, PaginationParams } from '@/app/helpers';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { UITable } from '@/shared/ui/ui-table';
import ConfirmModal from '@/shared/confirm-modal';
import { Link, useNavigate } from 'react-router-dom';
import { UIActionButton } from '../../../shared/ui/ui-action-button';
import { numericFormatter } from 'react-number-format';
import { UIChip } from '@/shared/ui/ui-chip';
import {
  useAddPaymentRegisterMutation,
  useDeletePaymentRegisterMutation,
  useGetPaymentRegistersAllQuery,
} from '@/app/api';
import { useAppSelector, useFilters } from '@/app/hooks';
import notify from '@/shared/toaster/lib/notify.tsx';
import dayjs from 'dayjs';

import { PlusIcon } from '@/shared/icons';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors.ts';
import { UIChipProps } from '@/shared/ui/ui-chip/types.ts';
import { ConfirmModalRef } from '@/shared/confirm-modal/types.ts';
import { PaymentRegistersAllRequest, PaymentRegistersStatus } from '@/app/api/model';

interface StatusOption {
  id: PaymentRegistersStatus;
  name: string;
}

interface FormValues {
  dateFrom: Date;
  dateTo: Date;
  search: string;
  status: StatusOption;
}

const STATUS_COLORS: Record<string, UIChipProps['color']> = {
  draft: 'purple',
  done: 'success',
  partially: 'warning',
  inProgress: 'info',
  default: 'error',
};

export const MutualSettlementsPaymentRegisters = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { roles } = useAppSelector((state) => state.auth);
  const [rowsToDelete, setRowsToDelete] = useState<number[]>([]);

  const { control, handleSubmit } = useForm<FormValues>();

  const { filterParams, onChangeFilterParams } = useFilters<
    PaginationParams<PaymentRegistersAllRequest>
  >({
    initialFilterParams: {
      page: 1,
      pageSize: 10,
      dateFrom: '',
      dateTo: '',
      status: '' as PaymentRegistersStatus,
    },
  });
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isSmallMobile = useMediaQuery('(max-width: 400px)');

  const confirmModalRef = useRef<ConfirmModalRef | null>(null);

  const { data, isFetching, isLoading } = useGetPaymentRegistersAllQuery(filterParams);
  const [addPaymentRegister, { isLoading: isAddPaymentRegisterLoading }] =
    useAddPaymentRegisterMutation();
  const [deletePaymentRegister] = useDeletePaymentRegisterMutation();

  const onSearchParamsSubmit: SubmitHandler<FormValues> = (data) => {
    onChangeFilterParams({
      page: 1,
      pageSize: 10,
      dateFrom:
        data.dateFrom && dayjs(data.dateFrom).isValid()
          ? dayjs(data.dateFrom).format('DD.MM.YYYY')
          : '',
      dateTo:
        data.dateTo && dayjs(data.dateTo).isValid() ? dayjs(data.dateTo).format('DD.MM.YYYY') : '',
      status: data.status.id as PaymentRegistersStatus,
    });
  };

  const addPaymentRegisterHandler = async () => {
    const { unique } = await addPaymentRegister({}).unwrap();
    navigate(`/settlements/payment-register/${unique}`);
    notify(t('toast.success'), 'success');
  };

  const deletePaymentRegisterHandler = async (unique: number) => {
    try {
      setRowsToDelete((prev) => [...prev, unique]);
      await confirmModalRef.current?.init({
        title: t('toast.warning'),
        text: t('delete_register_modal'),
        btnText: t('yes_delete'),
      });
      await deletePaymentRegister({ unique }).unwrap();
      notify(t('toast.success'), 'success');
    } finally {
      setRowsToDelete((prev) => prev.filter((row) => row !== unique));
    }
  };

  const getStatus = (status: PaymentRegistersStatus): UIChipProps => {
    const color = STATUS_COLORS[status] || STATUS_COLORS.default;

    return {
      color,
      label: t(`payment_registers_status.${status}`),
    };
  };

  const statusOptions = useMemo(() => {
    return [
      { id: '', name: t('payment_registers_status.all') },
      { id: 'draft', name: t('payment_registers_status.draft') },
      { id: 'partially', name: t('payment_registers_status.partially') },
      { id: 'done', name: t('payment_registers_status.done') },
      { id: 'inProgress', name: t('payment_registers_status.inProgress') },
    ] as const;
  }, [t]);

  const maxWidthDueToOptionsName = useMemo(() => {
    const longestLabel = statusOptions.reduce(
      (max, option) => (option.name.length > max.length ? option.name : max),
      ''
    );

    return `${longestLabel.length * 16}px`;
  }, [statusOptions]);

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18732']), [roles]);

  return (
    <Stack spacing={2}>
      <Stack
        component='form'
        direction={isLarge ? 'row' : 'column'}
        justifyContent='space-between'
        spacing={2}
        onSubmit={handleSubmit(onSearchParamsSubmit)}
      >
        <Stack direction={isTablet ? 'row' : 'column'} spacing={2}>
          <Stack direction='row' spacing={2}>
            <FormDatePicker
              control={control}
              name='dateFrom'
              label={`${t('from')}:`}
              format='DD.MM.YYYY'
            />
            <FormDatePicker
              control={control}
              name='dateTo'
              label={`${t('to')}:`}
              format='DD.MM.YYYY'
            />
          </Stack>

          <Stack direction='row' spacing={2}>
            <FormSelectInput
              control={control}
              name='status'
              options={statusOptions}
              disableClearable
              TextFieldProps={{
                label: t('payment_registers_status.all'),
                sx: {
                  minWidth: maxWidthDueToOptionsName,
                },
              }}
            />

            <UIButton type='submit' variant='contained' color='primary'>
              {t('to_find')}
            </UIButton>
          </Stack>
        </Stack>

        <Stack direction={isSmallMobile ? 'column-reverse' : 'row'} spacing={1.5}>
          <PageSizeSelect
            value={filterParams.pageSize}
            onChange={(event) =>
              onChangeFilterParams({ pageSize: Number(event.target.value), page: 1 })
            }
            sx={{ maxWidth: 100 }}
          />
          <PermissionCheckHOC
            WrappedComponent={UIButton}
            props={{
              color: 'primary',
              variant: 'contained',
              onClick: addPaymentRegisterHandler,
              loading: isAddPaymentRegisterLoading,
              disabled: !hasPermission || isLoading,
              children: t('add_payment_register'),
              sx: { whiteSpace: 'nowrap' },
            }}
          />
        </Stack>
      </Stack>

      <UITable
        data={data?.data ?? []}
        isLoading={isFetching}
        EmptyPlaceholder={
          <Stack spacing={2.5} alignItems='center' justifyContent='center' my={10}>
            <EmptyPlaceholder title={t('no_documents')} subtitle={t('no_payment_registers_text')} />
            <PermissionCheckHOC
              WrappedComponent={UIButton}
              props={{
                onClick: addPaymentRegisterHandler,
                endIcon: <PlusIcon />,
                disabled: !hasPermission,
                children: t('add_payment_register'),
              }}
            />
          </Stack>
        }
        columns={[
          {
            label: '№',
            render: ({ unique }) => (
              <Link to={`/settlements/payment-register/${unique}`}>{unique}</Link>
            ),
            nowrap: true,
          },
          {
            label: t('date'),
            render: ({ createdAt }) => createdAt,
          },
          {
            label: t('payment_registers_name'),
            render: ({ name }) => name,
          },
          {
            label: t('cell_name'),
            render: ({ status }) => status && <UIChip {...getStatus(status)} />,
          },
          {
            label: t('total_payment_registers'),
            render: ({ successfulRowsCount, rowsCount }) => (
              <Stack direction='row' spacing={1.5}>
                <Typography variant='body2' color={Colors.SECONDARY}>
                  {successfulRowsCount}
                </Typography>
                <Typography variant='body2' color={Colors.BGRAY}>
                  /
                </Typography>
                <Typography variant='body2' color={Colors.BGRAY}>
                  {rowsCount}
                </Typography>
              </Stack>
            ),
          },
          {
            label: t('sum'),
            render: ({ totalSum }) => (
              <Stack direction='row' spacing={1.5}>
                <Typography
                  variant='body1'
                  fontWeight={600}
                  lineHeight='2.4rem'
                  whiteSpace='nowrap'
                  color={Colors.SECONDARY}
                >
                  {numericFormatter(Number(totalSum / 100).toFixed(2), {
                    thousandSeparator: ' ',
                  })}
                </Typography>
              </Stack>
            ),
          },
          {
            label: '',
            render: ({ unique, status }) => (
              <Tooltip title={!hasPermission || status !== 'draft' ? '' : t('delete')}>
                <Box component='span'>
                  <PermissionCheckHOC
                    WrappedComponent={UIActionButton}
                    props={{
                      onClick: () => deletePaymentRegisterHandler(unique),
                      disabled:
                        !hasPermission || rowsToDelete.includes(unique) || status !== 'draft',
                      icon: 'delete',
                    }}
                  />
                </Box>
              </Tooltip>
            ),
            align: 'right',
          },
        ]}
      />

      <Pagination
        count={data?.totalPages ?? 0}
        page={filterParams.page}
        onChange={(_e, page) => onChangeFilterParams({ page })}
        sx={{
          display: !data?.data.length ? 'none' : 'flex',
          justifyContent: 'center',
        }}
      />

      <ConfirmModal ref={confirmModalRef} />
    </Stack>
  );
};
