import { WorkerAssigmentContractStatus, WorkerStatus } from '@/app/constants';
import { ActType, InvitationFlow } from '@/entities/auth/model';
import { DefaultResponse } from '../../helpers';

export interface Employee {
  unique: number;
  pinfl: string;
  phone: string;
  name: string;
  cardNumber: string;
  status: WorkerStatus;
  index: number;
  addressRegister: string;
  addressResidential: string;
  cardOwnerName?: string;
  assignmentContractStatus: WorkerAssigmentContractStatus;
  certificateNumber: string;
  agentContractStatus: WorkerAssigmentContractStatus;
  subCommissioner?: string;
  subCommissionerName?: string;
  excelImportError?: string;
  selfEmployed?: boolean;
  yourCommitent?: boolean;
  yandexSubcommitent?: boolean;
  createdAt?: string;
  yourCommitentEndsAt?: string;
  yandexSubcommitentEndsAt?: string;
  uzumSubcommitent?: boolean;
  uzumSubcommitentEndsAt?: string;
  contactPerson?: string;
  yandexDeliverySubcommitent?: boolean;
  yandexDeliverySubcommitentEndsAt?: string;
  userCardNumber?: string;
  userCardOwnerName?: string;
  selfEmployedTaxi?: boolean;
  selfEmployedDelivery?: boolean;
  assignmentContractName?: string;
  agentContractName?: string;
  selfEmployedCareForTheSickAndElderly?: boolean;
  selfEmployedNursing?: boolean;
  // * new properties according to DID-687
  actTypes: { code: number }[];
}

export type GetEmployeesFilterParams = Partial<{
  page: number;
  pageSize: number;
  search: string;
  yourCommitentFilter: boolean | string;
  uzumSubcommitentFilter: boolean | string;
  selfEmployedFilter: boolean | string;
  // * new properties
  yandexTaxiSubcommitentFilter: boolean | string;
  yandexDeliverySubcommitentFilter: boolean | string;
  status: string;
  // ! expired properties according to DID-687
  // selfEmployedTaxiFilter: boolean | string;
  // selfEmployedDeliveryFilter: boolean | string;
  // * new properties according to DID-687
  actTypes: number[] /* equals to the disabled properties 'selfEmployedTaxiFilter', 'selfEmployedDeliveryFilter' */;
  counterFilter: string /* equals to the disabled property 'status' */;
  excludedActTypes: number[];
}>;

export interface InviteWorkerBody {
  phone: string;
  pinfl: number;
  name: string;
  cardNumber: string;
  cardOwnerName: string | null;
  selfEmployeeRegistrationCode?: string;
  addressRegister: string;
  addressResidential: string;
  // subCommissioner?: string;
  // subCommissionerName?: string;
  taxiCompanyId?: number;
  contactPerson?: string;
  villageCode?: number;
  districtCode?: number;
  regionCode?: number;
}

export interface SendSmsVerificationCodeBody {
  pinfl: number;
  phone: string;
  invitationCode?: string;
}

export interface InvitationInfoResponse {
  card: string;
  name: string;
  phone: string;
  pinfl: string;
  faceIdRequired: boolean;
  hasSmsCode: boolean;
  invitationFlow?: InvitationFlow;
  subCommissioner?: string;
  subCommissionerName?: string;
  inn: string;
  actTypes?: {
    nameShortRu: string;
    nameShortUz: string;
  }[];
  regionName: string;
  regionCode: number;
  districtName: string;
  districtCode: number;
  villageName: string;
  villageCode: number;
}

export interface GetInfoByTinOrPinflResponse {
  fullName: string;
  fullname: string;
  name: string;
  shortName: string;
  shortname: string;
  personalNum: string;
  tin: string;
  address: string;
}

export interface CardDataResponse {
  name: string;
}

export interface EditEmployeeBody {
  unique: number;
  cardNumber: string;
  cardOwnerName: string;
  addressRegister: string;
  addressResidential: string;
  phone: string;
  taxiCompanyId?: number;
  contactPerson?: string;
}

export interface CompanyBalance {
  availableBalance: number;
  expenseTransactions: number;
  expenseTransactionsFor7days: number;
  incomeTransactions: number;
  incomeTransactionsFor7days: number;
  success: boolean;
}

export interface Transaction {
  unique: number;
  sender: {
    senderName: string;
    senderTIN?: string;
    senderPINFL?: string;
  };
  recipient: {
    recipientName: string;
    recipientTIN?: string;
    recipientPINFL?: string;
    recipientCardNumber?: string;
  };
  tId: string;
  typeName: string;
  amount: number;
  goodsName: string;
  statusName: string;
  procurementTypeName: string;
  createdAt: string;
  timeInMillis: string;
  action: string;
  availableBalance: number;
  index: number;
}

export type YandexSubFlow = 'taxi' | 'delivery';

export interface TaxiCompany {
  unique: number;
  name: string;
  isDefault: boolean;
  selected: boolean;
  yandexSubFlow?: YandexSubFlow;
  yandexSubFlows?: YandexSubFlow[];
  actTypes: ActType[];
}

export type ExcelFileStatus = 'inProgress' | 'done' | 'waiting';

export interface ExcelFileHistoryResponse {
  data: {
    createdAt: string;
    status: ExcelFileStatus;
    fileName: string;
    numberOfWorkers: number;
  }[];
  inProgressCount: number;
  doneCount: number;
  waitingCount: number;
  totalPages: number;
}

export interface YandexApiAccessBody {
  unique: string | number;
  clientId: string;
  apiKey: string;
  parkId: string;
}

export enum TaxiParkBotPercentage {
  FROM_TAXI_COMPANY = 'fromTaxiCompany',
  FROM_TAXI_DRIVER = 'fromTaxiDriver',
}

export interface TaxiParkBotSettings {
  limitOnWithdrawalsPerDay: number;
  minimumBalance: number;
  minimumAmountToWithdrawMoney: number;
  percentage: TaxiParkBotPercentage;
  checkSelfEmployment: boolean;
  checkCommittee: boolean;
  faceIdToRegister: boolean;
  replenishmentPercentage: TaxiParkBotPercentage;
}

export interface MutualSettlements {
  unique: number;
  name: string;
  phone: string;
  driverLicense: string;
  carNumber: string;
  pinfl: string;
  accessToWithdrawal: boolean;
}

export interface MutualSettlementsTransaction {
  date: string;
  driverName: string;
  cardNumber: string;
  cardOwner: string;
  amount: number;
  status: string;
  initiator: string;
  index: number;
  type: 'replenishment' | 'withdrawal';
}

export interface PaymentRegister {
  unique: number;
  date: string;
  id: string;
  name: string;
  index: number;
}

export interface SinglePaymentRegister {
  unique: number;
  success: boolean;
  pinfl: string;
  name: string;
  amount: number; // в тийинах
  cardNumber: string;
  index: number;
  error?: string;
}

export interface YandexDriverUpload {
  createdAt: string;
  status: string;
  downloadedWorkers: number;
  totalWorkers: number;
  finishedAt: string;
  errorText: string;
  index: number;
  hasNotDownloadedFile?: boolean;
  unique: number;
  notDownloadedCount?: number;
  section: 'calculations' | 'workers';
}

export type YandexDocStatus = 'draft' | 'waitContragentSign' | 'signed' | 'rejected' | 'canceled';

export interface YandexDoc {
  date: string;
  id: string;
  index: number;
  name: string;
  status: YandexDocStatus;
  unique: number;
  amount: number;
  didoxId?: string;
}

// Yandex Docs API types

export type DocumentReportTypeId = 'travel' | 'corporate' | 'reward';

export interface GetDocumentReportTypes {
  id: DocumentReportTypeId;
  name: string;
}

export type ClosingDocumentTypeId =
  | 'invoiceTaxi'
  | 'reportCommissioner'
  | 'invoiceCommission'
  | 'invoiceAggregator';

export interface GetClosingDocumentTypes {
  id: ClosingDocumentTypeId;
  name: string;
}

export type DocumentReportStatusId = 'posted' | 'draft' | 'canceled';

export interface GetDocumentReportStatuses {
  id: DocumentReportStatusId;
  name: string;
}

export interface GetDocumentStatuses {
  id: YandexDocStatus;
  name: string;
}

export interface EditYandexReportRow {
  pinfl?: string;
  amount?: number;
  aggregatorPercent?: number;
  taxiPercent?: number;
  taxiDriverReward?: number;
  unique?: number;
}

export interface EditYandexReport {
  unique: number;
  date: string;
  number: string;
  dateFrom: string;
  dateTo: string;
  contractNumber?: string;
  contractDate?: string;
  aggregatorPercent?: number;
  taxiPercent?: number;
  rows: EditYandexReportRow[];
  posted?: boolean;
  check?: boolean;
}

export interface AddReport {
  type: DocumentReportTypeId;
}

export interface GetReportsRequestBody {
  dateFrom?: string;
  dateTo?: string;
  status?: string;
  type?: string;
}

export interface GetYandexReportsData {
  unique: number;
  dateFrom: string;
  dateTo: string;
  type: string;
  status: string;
  amount: number | null;
  index: number;
  date: string;
  number: string;
}

export interface YandexReportRow {
  unique?: number;
  index?: number;
  name?: string;
  pinfl?: string;
  amount?: number;
  aggregatorPercent?: number;
  aggregatorCommission?: number;
  taxiPercent?: number;
  taxiCommission?: number;
  taxiDriverReward?: number;
  contractDate?: string;
  contractNumber?: string;
  documents?: CreatedClosingDocument[];
  delete?: boolean;
}

export interface ClosingDocumentToCreate {
  id: string;
  name: string;
}

export interface CreatedClosingDocument {
  didoxId: string;
  status: string;
  type: string;
  statusId: YandexDocStatus;
}

interface ReportHistory {
  created: string;
  edited: string;
  posted: string;
}

export interface GetYandexReportResponseBody {
  aggregatorPercent?: number;
  closingDocuments?: ClosingDocumentToCreate[];
  contractDate?: string;
  contractNumber?: string;
  date?: string;
  dateFrom: string;
  dateTo: string;
  documents?: CreatedClosingDocument[];
  number?: string;
  rows: YandexReportRow[];
  status: DocumentReportStatusId;
  taxiPercent?: number;
  nextPage?: number;
  type: { id: DocumentReportTypeId; name: string };
  totalPages?: number;
  success: boolean;
  creating?: boolean;
  history: ReportHistory;
}

export interface ImportReportRows {
  pinfl: string;
  name: string;
  aggregatorPercent?: number;
  taxiPercent?: number;
  amount?: number;
  taxiDriverReward?: number;
  contractDate?: string;
  contractNumber?: string;
}

export interface GetInvoicesRequestBody {
  dateFrom?: string;
  dateTo?: string;
  status?: string;
  type?: string;
}

export interface GetInvoicesResponseBody {
  didoxId: string;
  date: string;
  number: string;
  type: string;
  status: YandexDocStatus;
  amount: number;
  id: string;
  index: number;
  unique: number;
  basedDocument: { unique: number; name: string };
}

// export interface GetInvoiceCreateStatus {
//   success?: boolean;
//   creating?: boolean | null;
//   invoices?: number;
//   rows: number;
//   percent?: number;
// }

export interface GetCreateStatus {
  success?: boolean;
  creating?: boolean | null;
  created?: number;
  rows: number;
  percent?: number;
}

export interface GetUklonRerportsData {
  unique: number;
  date: string;
  number: string;
  index: number;
  status: string;
}

export interface UklonReportRow {
  index: number; // Номер строки
  pinfl: string; // Пинфл
  // periodFrom: string; // Период от
  // periodTo: string; // Период до
  tripCostCash: number; // Стоимость поездки (наличные)
  tripCostNonCash: number; // Стоимость поездки (безналичные)
  promoCodeCash: number; // в т.ч. промокод нал
  promoCodeNonCash: number; // в т.ч. промокод безнал
  uklonCommissionCash: number; // Комиссия Уклона (наличные)
  uklonCommissionNonCash: number; // Комиссия Уклона (безналичные)
  compensations: number | null; // Компенсации
  dayProgramBonuses: number; // Бонусы по дневным программам
  orderBonuses: number; // Бонусы за каждый заказ
  tipsNonCash: number | null; // Чаевые (безналичные)
  brandingBonuses: number | null; // Бонусы за брендирование авто
  fines: number | null; // Штрафы
  walletReplenishmentCommission: number | null; // Комиссия за пополнение кошелька
  walletTransfer: number; // Перевод между кошельками
  cashPayment: number | null; // Оплата наличными
  withdrawalToDriverCard: number; // Вывод денег из кошелька на карту водителя
  withdrawalCommissionToDriverCard: number | null; // Комиссия за вывод денег из кошелька на карту водителя
  driverWalletTransfer: number | null; // Перевод между кошельками водителем
  driverWalletReplenishment: number | null; // Пополнение кошелька водителем
  delete: boolean;
  active: false;
  name: string | null;
  netIncomeCash: null;
  netIncomeCashless: null;
  netProfit: null;
  unique: number;
}

export interface GetUklonReportResponseBody {
  success: boolean; // Признак успешности
  history: History; // История (объект)
  created: string; // Дата создания
  edited: string; // Дата редактирования
  posted: string; // Дата проведения
  date: string; // Дата документа
  number: string; // Номер документа
  status: string; // Статус документа
  rows: UklonReportRow[];
  totalPages?: number; // Количество страниц
  nextPage?: number;
  dateFrom: string;
  dateTo: string;
}

export interface EditUklonReport {
  unique: number;
  posted?: boolean;
  number: string;
  rows: UklonReportRow[];
  date: string;
  dateFrom: string;
  dateTo: string;
}
export interface BaseLocationData {
  code: number;
  name: string;
  nameRu: string;
}

export interface GetCompanyWorkersCounters {
  statuses: { count: number; id: string; name: string; nameUz: string }[];
  elements: number;
}

export type GetCompanyWorker = DefaultResponse<
  Employee &
    Partial<{
      districtName: string;
      districtNameRu: string;
      regionName: string;
      regionNameRu: string;
      villageName: string;
      // new properties according to VD-465/467:
      activeSince: string;
      createdAt: string;
      invitedAt: string;
    }>
>;

export type PaymentRegistersStatus = 'draft' | 'partially' | 'done' | 'inProgress';

export interface PaymentRegistersAllRequest {
  dateFrom: string;
  dateTo: string;
  status: PaymentRegistersStatus;
}

export interface PaymentRegistersResponse {
  unique: number;
  createdAt: string;
  name: string;
  status: PaymentRegistersStatus;
  rowsCount: number;
  successfulRowsCount: number;
  totalSum: number;
  index: number;
}

// GET Row
export interface PaymentRegisterRequest {
  unique: number;
  userNotFoundFilter: boolean;
  wrongDataFilter: boolean;
}

export interface PaymentRegisterRow {
  unique: number;
  cardNumber: string;
  cardOwner: string;
  userName: string;
  userPinfl: string;
  amount: number;
  paid: boolean;
  errorCode: number;
  userSelfEmployed: boolean;
  yourCommitent: boolean;
  aggregatorSubComitee: boolean;
  userNotFound: boolean;
  cardNotFound: boolean;
  index: number;
}

export interface PaymentRegisterResponse {
  name: string;
  status: PaymentRegistersStatus;
  elements: number;
  totalSum: number;
  unique: number;
  wrongDataCount: number;
  userNotFoundCount: number;
  rows: PaymentRegisterRow[];
  totalPages: number;
}

// Add or Edit Row Request
export interface AddOrEditPaymentRegisterRowRequest {
  unique: number;
  pinfl: string;
  cardNumber: string;
  cardOwner: string;
  amount: number;
}

// Edit Row Response
export interface EditPaymentRegisterRowBody {
  unique: number;
  cardNumber: string;
  cardOwner: string;
  userName: string;
  userPinfl: string;
  amount: number;
  yourCommitent: boolean;
  aggregatorSubComitee: boolean;
  success: boolean;
}

export interface CompanyDriverWithCompleteOrdersRequest {
  search: string;
  dateFrom: string;
  dateTo: string;
}

export interface CompanyDriverWithCompleteOrdersRow {
  fullname: string;
  pinfl: string;
  count: number;
  index: number;
}
export interface CompanyDriverWithCompleteOrdersResponse {
  data: CompanyDriverWithCompleteOrdersRow[];
  totalCount: number;
  totalPages: number;
  nextPage: number;
}
